'use strict';

(function () {
    $(function () {
        main();
    });

    function main() {
        $("#search-button").on("click", function (e) {
            const $form = $("#search-form");
            const $input = $("#search-input");
            if ($form.hasClass("closed")) {
                $form.removeClass("closed");
                $input.focus();
                e.preventDefault();
            }
            else {
                if ($input.val().length === 0) {
                    $form.addClass("closed");
                    e.preventDefault();
                }
            }
        });

        setupMenu();
        setupNavs();
        setupAds();
    }

    function setupMenu() {
        let $menu = $("#menu-container");
        $("#menu-button").on("click", function () {
            $menu.addClass("open");
        });
        $("#menu-close-button").on("click", function () {
            $menu.removeClass("open");
        });
    }

    function setupNavs() {
        $(".vscroll-nav, .hscroll-nav").each(function () {
            let $parent = $(this);
            let $ul = $parent.children("ul");

            $parent.append("<button class='scroll-start-btn scroll-btn'></button>" +
                "<button class='scroll-end-btn scroll-btn'></button>");

            $ul.each(function () {
                checkOverflow(this);
            });

            $ul.on("scroll", function () {
                checkOverflow(this);
            });

            $ul.on("size", function () {
                checkOverflow(this);
            });

            $(window).on("resize", function () {
                $ul.each(function () {
                    checkOverflow(this);
                });
            });

            $parent.on("size", function () {
                $ul.each(function () {
                    checkOverflow(this);
                });
            });

            $parent.children(".scroll-start-btn").on("click", function () {
                $ul.each(function () {
                    this.scrollLeft -= this.clientWidth;
                    this.scrollTop -= this.clientHeight;
                });
            });

            $parent.children(".scroll-end-btn").on("click", function () {
                $ul.each(function () {
                    this.scrollLeft += this.clientWidth;
                    this.scrollTop += this.clientHeight;
                });
            });

            function checkOverflow(ul) {
                if (ul.scrollHeight > ul.clientHeight ||
                    ul.scrollWidth > ul.clientWidth) {
                    $parent.addClass("has-overflow");
                }
                else {
                    $parent.removeClass("has-overflow");
                }

                if (Math.floor(ul.scrollTop) > 0 ||
                    Math.floor(ul.scrollLeft) > 0) {
                    $parent.addClass("scroll-start");
                }
                else {
                    $parent.removeClass("scroll-start");
                }

                if (Math.ceil(ul.scrollTop + ul.clientHeight) < ul.scrollHeight ||
                    Math.ceil(ul.scrollLeft + ul.clientWidth) < ul.scrollWidth) {
                    $parent.addClass("scroll-end");
                }
                else {
                    $parent.removeClass("scroll-end");
                }
            }
        });
    }

    var adleave = 0;

    function setupAds() {
        var $textAds = $(".ad");

        $textAds.each(function () {
            let curAd = this;

            $(this).on("mouseenter",
                function () {
                    if (adleave !== 0) {
                        clearTimeout(adleave);
                    }

                    $(this).removeClass("faded");

                    $textAds.each(function () {
                        if (curAd !== this) {
                            $(this).addClass("faded");
                        }
                    });
                });

            $(this).on("mouseleave",
                function () {
                    adleave = setTimeout(function () {
                        $textAds.each(function () {
                            $(this).removeClass("faded");
                        });
                    }, 300);
                });
        });
    }

})();